import React, { useEffect, useState } from 'react';
import { Button, Card, Flex, Form, FormInstance } from 'antd';
import { useTranslation } from 'react-i18next';
import ProductComponentAPI from 'services/product-component.service';
import { IClientRequest } from 'store/client-request/client-request.types';
import { IProductComponent } from 'store/product-component/product-components.types';
import ProductFormItem from './ProductsFormItem';
import { IOfferDocumentForm } from '../OfferDocumentGenerator';

interface IOfferDocumentProductFormProps {
  clientRequest?: IClientRequest;
  form: FormInstance<IOfferDocumentForm>;
}
const OfferDocumentProductForm = ({ clientRequest, form }: IOfferDocumentProductFormProps) => {
  const [productComponents, setProductComponents] = useState<IProductComponent[]>([]);
  const locizeKey = 'intermediate:pdfGen:product';
  const { t } = useTranslation();
  const fetchProducts = async () => {
    if (!clientRequest?.poolId) return;
    const { items } = (await ProductComponentAPI.getProductWizardWithHeaders(clientRequest.poolId, {
      'Accept-Language': clientRequest.owner?.defaultLanguage.isoCode,
    })) as {
      items: IProductComponent[];
    };
    setProductComponents(items);
  };
  useEffect(() => {
    fetchProducts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clientRequest]);
  return (
    <Card title={t(`${locizeKey}s:title`)}>
      <Form.List name={'products'}>
        {(fields, { add, remove }) => (
          <Flex vertical={true} gap={16}>
            {fields.map((field) => (
              <ProductFormItem
                form={form}
                productComponents={productComponents}
                key={field.key}
                field={field}
                vatRate={clientRequest?.pool?.country.vatRate || 0}
                remove={remove}
              />
            ))}

            {fields.length <
              productComponents.reduce(
                (total, _productComponent) => total + (_productComponent.products?.length || 0),
                0,
              ) && <Button onClick={add}>{t(`${locizeKey}:addProduct`)}</Button>}
          </Flex>
        )}
      </Form.List>
    </Card>
  );
};
export default OfferDocumentProductForm;
