import React, { useCallback, useEffect } from 'react';
import { Card, Form, FormInstance, Descriptions, Flex, Button } from 'antd';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { bundlesGet } from 'store/bundle/bundle.actions';
import { IClientRequest } from 'store/client-request/client-request.types';
import { formatPrice } from 'utils/currencyUtils';
import BundleSelectForm from './BundleSelectForm';
import { IOfferDocumentForm } from '../OfferDocumentGenerator';
import { IOfferDocumentCosts } from '../useCalculateCosts';

interface IOfferDocumentBundlesFormProps {
  clientRequest?: IClientRequest;
  form: FormInstance<IOfferDocumentForm>;
  installationCosts: IOfferDocumentCosts;
}

const OfferDocumentBundlesForm = ({
  clientRequest,
  form,
  installationCosts,
}: IOfferDocumentBundlesFormProps) => {
  const pool = clientRequest?.pool;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const loadBundles = useCallback(() => {
    if (clientRequest?.pool?.id) {
      dispatch(bundlesGet(clientRequest.pool.id));
    }
  }, [dispatch, clientRequest]);
  useEffect(() => {
    loadBundles();
  }, [loadBundles]);

  return (
    <Card title={t('intermediate:pdfGen:bundle:title')}>
      <Flex gap={16} vertical={true}>
        <Form.List name={['bundles']}>
          {(fields, { add, remove }) => (
            <Flex vertical={true} gap={4}>
              {fields.map((field) => (
                <BundleSelectForm
                  removeField={remove}
                  fieldIndex={field.name}
                  key={field.key}
                  form={form}
                />
              ))}
              <Button onClick={() => add()}>{t(`intermediate:myProjects:bundle:newBundle`)}</Button>
            </Flex>
          )}
        </Form.List>
        <Descriptions layout={'horizontal'} column={2}>
          <Descriptions.Item label={t(`intermediate:myProjects:bundle.bundleCurrency`)}>
            {pool?.country.currency}
          </Descriptions.Item>
          <Descriptions.Item label={t('intermediate:myProjects:bundle:vat')}>
            {pool?.country.vatRate}
          </Descriptions.Item>
          <Descriptions.Item label={t(`intermediate:myProjects:bundle.totalBundlePriceNet`)}>
            {formatPrice(installationCosts.net)}
          </Descriptions.Item>
          <Descriptions.Item label={t(`intermediate:myProjects:bundle.vat`)}>
            {formatPrice(installationCosts.vat)}
          </Descriptions.Item>
          <Descriptions.Item label={t(`intermediate:myProjects:bundle.totalBundlePriceGross`)}>
            {formatPrice(installationCosts.gross)}
          </Descriptions.Item>
        </Descriptions>
      </Flex>
    </Card>
  );
};
export default OfferDocumentBundlesForm;
