import React from 'react';
import { Button, Flex, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import Rectangle from 'components/layout/Rectangle/Rectangle';
import { ClientRequestState, IClientRequest } from 'store/client-request/client-request.types';
import { OrderState } from 'store/orders/orders.types';
import { IOffer, OfferState } from 'store/offer/offer.types';
import { checkIsHomeCheck, checkIsInstallation } from 'utils/offerHelpers';
import Styles from './ActionWidget.module.sass';

const LOCIZE_KEY = 'installerFlow';
const getCurrentOffer = (offers: IOffer[]): IOffer | undefined => {
  const installationOffer = offers.find(
    (offer) =>
      offer.state &&
      checkIsInstallation(offer.state) &&
      offer.state !== OfferState.InstallationDone,
  );
  if (installationOffer) return installationOffer;
  return offers.find((offer) => offer.state && checkIsHomeCheck(offer.state));
};
const ActionWidget = ({ clientRequest }: { clientRequest: IClientRequest }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const unconfirmedOrder = clientRequest.orders?.find(
    (order) => order.state === OrderState.Created,
  );
  const handleContinueClick = () => {
    if (unconfirmedOrder) {
      navigate(`/clientes/orders/${clientRequest.id}`);
      return;
    }
    const currentOffer = clientRequest.offers && getCurrentOffer(clientRequest.offers);
    if (currentOffer) {
      navigate(`/installer/order/${currentOffer.id}/details`);
      return;
    }
  };
  const getButtonContent = () => {
    if (clientRequest.state === ClientRequestState.Done)
      return t(`${LOCIZE_KEY}:actionWidget:done`);
    if (unconfirmedOrder) return t(`${LOCIZE_KEY}:actionWidget:acceptJob`);
    return t(`${LOCIZE_KEY}:actionWidget:continue`);
  };
  return (
    <Rectangle contentClassName={Styles.Container} title={t(`${LOCIZE_KEY}:actionWidget:title`)}>
      <Flex vertical={true} gap={8} justify="space-between" flex={1}>
        <Typography.Text>{t(`${LOCIZE_KEY}:actionWidget:text`)}</Typography.Text>

        <Button
          type={'primary'}
          className={Styles.FullWidthButton}
          onClick={handleContinueClick}
          disabled={clientRequest.state === ClientRequestState.Done}
        >
          {getButtonContent()}
        </Button>
      </Flex>
    </Rectangle>
  );
};
export default ActionWidget;
