import React from 'react';
import { Button, Flex } from 'antd';
import { useTranslation } from 'react-i18next';

interface IActionButtonsProps {
  isOfferPublishable: boolean;
  downloadPDFClickHandler: () => void;
  cancelButtonClickHandler: () => void;
  sendOfferClickHandler: () => void;
  isOfferSubmitting: boolean;
}
const ActionButtons = ({
  cancelButtonClickHandler,
  sendOfferClickHandler,
  downloadPDFClickHandler,
  isOfferPublishable,
  isOfferSubmitting,
}: IActionButtonsProps) => {
  const { t } = useTranslation();
  const locizeKey = 'intermediate:pdfGen:buttons';

  return (
    <Flex justify={'space-between'}>
      <Flex>
        <Button onClick={cancelButtonClickHandler}>{t(`${locizeKey}:cancel`)}</Button>
      </Flex>
      <Flex gap={4}>
        <Button onClick={downloadPDFClickHandler}>{t(`${locizeKey}:download`)}</Button>
        <Button
          onClick={sendOfferClickHandler}
          loading={isOfferSubmitting}
          type={'primary'}
          disabled={!isOfferPublishable}
        >
          {t(`${locizeKey}:send`)}
        </Button>
      </Flex>
    </Flex>
  );
};
export default ActionButtons;
